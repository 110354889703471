const LayoutDefault = () =>
  import(/* webpackChunkName: "kp-penjualan" */ '@/layouts/LayoutDefault.vue');
const Dashboard = () =>
  import(/* webpackChunkName: "kp-penjualan" */ '@/views/pelayanan/Index.vue');
// Transaksi Penjualan
const TransaksiPenjualan = () =>
  import(
    /* webpackChunkName: "kp-penjualan" */ '@/views/pelayanan/penjualan/transaksi/Index.vue'
  );
const TambahTransaksiPenjualan = () =>
  import(
    /* webpackChunkName: "kp-penjualan" */ '@/views/pelayanan/penjualan/transaksi/Tambah.vue'
  );
const RestockPbs = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/pelayanan/penjualan/restock-pbs/Index.vue'
  );

const LayoutData = () =>
  import(/* webpackChunkName: "kp-penjualan" */ '@/layouts/LayoutData.vue');

let root = '/penjualan';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Kantor Pelayanan',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/transaksi-penjualan/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Transaksi Penjualan',
    },
    children: [
      {
        path: '',
        component: TransaksiPenjualan,
        meta: {
          auth: true,
          title: 'Transaksi Penjualan | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-transaksi-penjualan',
        component: TambahTransaksiPenjualan,
        meta: {
          auth: true,
          title: 'Tambah Transaksi Penjualan | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Transaksi Penjualan',
        },
      },
      {
        path: ':id',
        component: TambahTransaksiPenjualan,
        meta: {
          auth: true,
          title: 'Edit Transaksi Penjualan | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/restock-pbs/',
    component: RestockPbs,
    meta: {
      auth: true,
      title: 'Restock P3S | Penjualan Pusat',
      layout: LayoutDefault,
      breadcrumb: 'Restock P3S',
    }
  },
];
