<template>
  <div v-if="false">
    <div
      id="mega-menu-full-dropdown"
      class="absolute z-10 mt-1 w-screen border-y border-gray-200 bg-white shadow-sm dark:border-gray-600 dark:bg-gray-800"
    >
      <div class="">
        <ul
          class="mx-auto grid px-4 py-5 text-gray-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6"
          aria-labelledby="mega-menu-full-dropdown-button"
        >
          <li v-for="menu in menus" :key="menu.id">
            <router-link
              :to="
                menu.attributes.url[0] == '/'
                  ? menu.attributes.url
                  : '/' + menu.attributes.url
              "
              :exact-active-class="'bg-gray-50'"
              class="block rounded-lg p-3 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              <div class="font-semibold">{{ menu.attributes.name }}</div>
              <span class="text-sm text-gray-500 dark:text-gray-400">{{
                menu.attributes.description ??
                `Menu Pengelolaan ${menu.attributes.name}.`
              }}</span>
              <li
                class="text-sm text-gray-500 hover:text-gray-600 hover:underline dark:text-gray-400"
                v-for="child in children(menu)"
                :key="child.id"
              >
                <router-link :to="child.attributes.url">
                  > {{ child.attributes.name }}
                </router-link>
              </li>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ItemDropdown',
  components: {},
  props: {
    menus: {
      type: Array,
      required: true,
    },
    value: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      getMenus: 'menus/getMenus',
    }),
  },
  methods: {
    children(menu) {
      return this.getMenus.data.filter(
        (it) => it.attributes.parent_id === menu.id
      );
    },
  },
};
</script>
