<template>
  <div class="max-w-full">
    <div
      :style="withOverflow ? 'overflow: initial' : {}"
      :class="[
        'max-w-full border-b border-gray-200 shadow sm:rounded-lg',
        scrollX ? 'overflow-x-auto' : 'overflow-hidden'
      ]"
    >
      <table class="min-w-full divide-y divide-gray-200" :id="id">
        <slot :classes="classes"></slot>
        <slot name="thead">
          <!-- default thead slot -->
          <thead :class="theadColor">
            <tr>
              <th
                v-for="column in columns"
                :key="column.id"
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider',
                  column.theadClass,
                ]"
                :style="column.theadStyle"
              >
                <slot v-if="column.customSlot" name="th" v-bind="column">
                  {{ column.name }}
                </slot>
                <template v-else>{{ column.name }}</template>
              </th>
            </tr>
          </thead>
        </slot>

        <tbody v-if="isEmpty">
          <tr class="bg-white">
            <td
              :colspan="columns.length > 0 ? columns.length : 12"
              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
            >
              <slot name="empty">
                {{ emptyMessage }}
              </slot>
            </td>
          </tr>
        </tbody>
        <slot
          v-else
          name="tbody"
          :classes="{
            tbody: 'bg-white divide-y divide-gray-200',
            tr: 'whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900',
            td: 'whitespace-nowrap px-6 py-4 text-sm text-gray-500 font-normal',
          }"
        >
          <!-- default tbody slot -->
          <tbody v-if="!data.length">
            <tr class="bg-white">
              <td
                :colspan="columns.length"
                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
              >
                <slot name="empty">
                  {{ emptyMessage }}
                </slot>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="(item, index) in data"
              :key="item.id"
              :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            >
              <td
                v-for="column in columns"
                :key="column.id"
                :class="[
                  'whitespace-nowrap px-6 py-4 text-sm text-gray-500',
                  column.tbodyClass,
                ]"
              >
                <span>{{
                  column.value
                    ? typeof column.value === 'function'
                      ? column.value.call(null, item)
                      : column.value
                    : item[column.id]
                }}</span>
              </td>
            </tr>
          </tbody>
        </slot>
        <slot
          name="tfoot"
          :classes="{
            tbody: 'bg-white',
            tr: 'whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900',
            td: 'whitespace-nowrap px-6 py-4 text-sm text-gray-500 font-normal',
          }"
        />
      </table>
    </div>
    <nav v-if="cursor" class="mt-8 flex justify-end" aria-label="Pagination">
      <base-button
        @click="onClickPage('prev')"
        color="white"
        id="prev"
        :disabled="currPage == 1"
      >
        <Icon class="h-5 w-5" icon="heroicons:chevron-left-20-solid" />
      </base-button>
      <base-button
        @click="onClickPage('next')"
        id="next"
        :disabled="isInLastPage"
        color="white"
      >
        <Icon class="h-5 w-5" icon="heroicons:chevron-right-20-solid" />
      </base-button>
    </nav>
    <div v-else-if="footer && total > 0" class="mt-8 flex justify-between">
      <span class="text-sm text-gray-700"
        >Menampilkan
        <span class="font-medium">{{
          total > 0 ? (currentPage - 1) * perPage + 1 : 0
        }}</span>
        -
        <span class="font-medium">{{
          total > 0
            ? perPage * currentPage > total
              ? total
              : perPage * currentPage
            : 0
        }}</span>
        total <span class="font-medium">{{ total }}</span> data
      </span>
      <nav v-if="paginated" class="flex" aria-label="Pagination">
        <!-- prev -->
        <button
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
          class="relative inline-flex items-center rounded-l-md border border-gray-200 bg-white px-2 py-2 text-sm font-normal text-gray-500 hover:bg-gray-50"
        >
          <Icon class="h-5 w-5" icon="heroicons:chevron-left-20-solid" />
        </button>
        <template v-if="currentPage > maxVisibleButton">
          <button
            @click="handlePageChange(1)"
            type="button"
            class="relative inline-flex items-center border border-gray-200 bg-white px-4 py-2 text-sm font-normal text-gray-500 hover:bg-gray-50"
          >
            1
          </button>
          <button
            type="button"
            class="relative inline-flex items-center border border-gray-200 bg-white px-4 py-2 text-sm font-normal text-gray-500 hover:bg-gray-50"
          >
            ...
          </button>
        </template>
        <div v-for="page in pages" :key="page.name">
          <button
            type="button"
            @click="onClickPage(page.name)"
            :disabled="page.isDisabled"
            :class="[
              isPageActive(page.name)
                ? 'bg-green-200 font-semibold text-green-500'
                : null,
            ]"
            class="relative inline-flex items-center border border-gray-200 bg-white px-4 py-2 text-sm font-normal text-gray-500 hover:bg-gray-50"
          >
            {{ page.name }}
          </button>
        </div>

        <template
          v-if="currentPage + 1 < totalPage && totalPage != maxVisibleButton"
        >
          <button
            type="button"
            class="relative inline-flex items-center border border-gray-200 bg-white px-4 py-2 text-sm font-normal text-gray-500 hover:bg-gray-50"
          >
            ...
          </button>
          <button
            @click="handlePageChange(totalPage)"
            type="button"
            class="relative inline-flex items-center border border-gray-200 bg-white px-4 py-2 text-sm font-normal text-gray-500 hover:bg-gray-50"
          >
            {{ totalPage }}
          </button>
        </template>
        <!-- next -->
        <button
          @click="onClickNextPage"
          :disabled="isInLastPage"
          class="relative inline-flex items-center rounded-r-md border border-gray-200 bg-white px-2 py-2 text-sm font-normal text-gray-500 hover:bg-gray-50"
        >
          <Icon class="h-5 w-5" icon="heroicons:chevron-right-20-solid" />
        </button>
      </nav>
    </div>
  </div>
</template>
<script>
import debounce from 'debounce';
import BaseButton from './BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
  props: {
    id: String,
    // pagination
    paginated: {
      type: Boolean,
      default: true,
    },
    withOverflow: {
      type: Boolean,
      default: false,
    },
    total: {
      type: [Number, String],
    },
    perPage: {
      type: [Number, String],
    },
    currentPage: {
      default: 1,
      type: [Number, String],
    },
    maxVisibleButton: {
      type: Number,
      default: 3,
    },
    footer: {
      type: Boolean,
      default: true,
    },
    classes: {
      header:
        'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500',
      body: 'whitespace-nowrap px-6 py-4 text-sm text-gray-500',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    emptyMessage: {
      type: String,
      default: 'Data Kosong',
    },
    meta: {
      type: Object,
    },
    cursor: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    scrollX: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      currPage: 1,
    };
  },
  computed: {
    theadColor() {
      return {
        default: 'bg-gray-50 text-gray-500',
        green: 'bg-green-600 text-white'
      }[this.color]
    },
    // Pagination
    totalPage() {
      return Math.ceil(this.total / this.perPage);
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      if (this.cursor) {
        if (this.meta?.page?.hasMore) {
          return false;
        } else {
          return true;
        }
      }

      return this.currentPage === this.totalPage;
    },
    startPage() {
      if (this.currentPage > 0 && this.currentPage < this.maxVisibleButton)
        return 1;
      if (this.currentPage === this.totalPage)
        return this.totalPage - this.maxVisibleButton + 1;
      return this.currentPage - 1;
    },
    pages() {
      const range = [];
      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.maxVisibleButton - 1, this.totalPage);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }
      return range;
    },
  },
  methods: {
    // Pagination
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage: debounce(function (page) {
      if (page == 'next') {
        this.currPage += 1;
      } else {
        this.currPage -= 1;
      }
      const params = {
        'page[after]': page == 'next' ? this.meta.page.to : undefined,
        'page[before]': page == 'prev' ? this.meta.page.from : undefined,
      };
      this.$emit(
        'pagechanged',
        page == 'next' || page == 'prev' ? params : page
      );
    }, 100),
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
    handlePageChange(page) {
      this.$emit('pagechanged', page);
    },
  },
};
</script>
