import LayoutDefault from '@/layouts/LayoutDefault.vue';

import Admin from './admin.route';
import Gudang from './gudang.route';
import CashBill from './cash-bill.route';
import Kasir from './kasir.route';

let stockist = [
  {
    path: '/saldo',
    name: 'MyBalance',
    component: () => import('@/views/stockist/Balance.vue'),
    meta: {
      auth: true,
      title: 'Saldo Saya',
      layout: LayoutDefault,
    },
  },
];

stockist = stockist.concat(Admin, Gudang, CashBill, Kasir);

export default stockist;
