<template>
  <base-input :label="label">
    <div class="relative rounded-md shadow-sm h-full">
      <button
        class="absolute inset-y-0 left-0 flex items-center pl-3"
        @click="resetInput"
        :class="[!resetable ? 'pointer-events-none' : null]"
      >
        <div class="absolute inset-y-0 left-0 flex items-center pl-3">
          <Icon v-if="!resetable" icon="ic:round-search" class="h-5 w-5" />
          <Icon v-else icon="heroicons:x-mark-20-solid" class="h-5 w-5" />
        </div>
      </button>
      <input
        id="search"
        type="text"
        v-model="tmpValue"
        @input="onSearch"
        @keydown.enter="onSearch"
        @keyup="onSearch"
        autocomplete="off"
        class="block w-full rounded-md border-gray-300 pl-10 focus:border-green-500 focus:ring-green-500 sm:text-sm h-full"
        :placeholder="placeholder"
      />
    </div>
  </base-input>
</template>

<script>
import debounce from 'debounce';

export default {
  name: 'BaseSearch',
  props: {
    placeholder: {
      type: String,
      default: 'Search',
    },
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    withThreeLetterValidation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tmpValue: '',
    };
  },
  computed: {
    resetable() {
      return this.tmpValue?.length > 0;
    },
  },
  methods: {
    resetInput() {
      if (this.resetable) {
        this.tmpValue = '';

        this.$emit('input', this.tmpValue);
      }
    },
    onSearch: debounce(function () {
      this.$emit('input', this.tmpValue);
    }, 300),
  },
};
</script>
