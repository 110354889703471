const LayoutDefault = () =>
  import(/* webpackChunkName: "stockist" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "stockist" */ '@/layouts/LayoutData.vue');

const Dashboard = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/cash-bill/dashboard/Index.vue'
  );

const TransaksiCashBill = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/cash-bill/transaksi-cash-bill/Index.vue'
  );
const TambahTransaksiCashBill = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/cash-bill/transaksi-cash-bill/TambahPenjualan.vue'
  );

const TambahPoint = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/cash-bill/tambah-point/Index.vue'
  );
const PencatatanTambahPoint = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/cash-bill/tambah-point/TambahPoint.vue'
  );

const PenjualanPbs = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/cash-bill/penjualan-pbs/Index.vue'
  );
const TambahPenjualanPbs = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/cash-bill/penjualan-pbs/Tambah.vue'
  );

const LaporanPenjualanPbs = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/cash-bill/laporan-penjualan-pbs/Index.vue'
  );
const TambahLaporanPenjualanPbs = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/cash-bill/laporan-penjualan-pbs/Tambah.vue'
  );

let root = '/cashbill';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Cash Bill',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/transaksi-cashbill/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Transaksi Cash Bill',
    },
    children: [
      {
        path: '',
        component: TransaksiCashBill,
        meta: {
          auth: true,
          title: 'Transaksi Cash Bill | Cash Bill',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-transaksi-cash-bill',
        component: TambahTransaksiCashBill,
        meta: {
          auth: true,
          title: 'Tambah Transaksi Cash Bill | Cash Bill',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Transaksi Cash Bill',
        },
      },
      {
        path: ':id',
        component: TambahTransaksiCashBill,
        meta: {
          auth: true,
          title: 'Edit Transaksi Cash Bill | Cash Bill',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/pencatatan-tambah-poin/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pencatatan Tambah Poin',
    },
    children: [
      {
        path: '',
        component: TambahPoint,
        meta: {
          auth: true,
          title: 'Pencatatan Tambah Poin | Cash Bill',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-poin',
        component: PencatatanTambahPoint,
        meta: {
          auth: true,
          title: 'Transaksi Pencatatan Tambah Poin | Cash Bill',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Pencatatan Tambah Poin',
        },
      },
      {
        path: ':id',
        component: PencatatanTambahPoint,
        meta: {
          auth: true,
          title: 'Edit Pencatatan Tambah Poin | Cash Bill',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/penjualan-pbs/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Penjualan P3S',
    },
    children: [
      {
        path: '',
        component: PenjualanPbs,
        name: 'penjualan-pbs',
        meta: {
          auth: true,
          title: 'Penjualan P3S | Cash Bill',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah',
        component: TambahPenjualanPbs,
        name: 'penjualan-pbs.tambah',
        meta: {
          auth: true,
          title: 'Tambah Penjualan P3S | Cash Bill',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Penjualan P3S',
        },
      },
      {
        path: ':id',
        component: TambahPenjualanPbs,
        name: 'penjualan-pbs.edit',
        meta: {
          auth: true,
          title: 'Edit Penjualan P3S | Cash Bill',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/laporan-penjualan-pbs/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Laporan Penjualan P3S',
    },
    children: [
      {
        path: '',
        component: LaporanPenjualanPbs,
        name: 'laporan-penjualan-pbs',
        meta: {
          auth: true,
          title: 'Laporan Penjualan P3S | Cash Bill',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah',
        component: TambahLaporanPenjualanPbs,
        name: 'laporan-penjualan-pbs.tambah',
        meta: {
          auth: true,
          title: 'Tambah Laporan Penjualan P3S | Cash Bill',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Laporan Penjualan P3S',
        },
      }
    ],
  },
];
