<script>
export default {
  name: 'BaseButton',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'md',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    forIcon: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    to: [String, Object],
  },
  emits: ['click'],
  computed: {
    style: function () {
      const colors = {
        white: 'bg-white border-gray-300 text-gray-700 focus:ring-green-500',
        primary:
          'bg-green-600 focus:ring-green-500 hover:bg-green-700 text-white',
        success:
          'bg-green-600 focus:ring-green-500 hover:bg-green-700 text-white',
        secondary:
          'focus:outline-none text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-500 font-medium rounded-lg dark:focus:ring-yellow-900',
        danger: 'bg-red-600 focus:ring-red-500 hover:bg-red-700 text-white',
        warning:
          'bg-yellow-600 focus:ring-yellow-500 hover:bg-yellow-700 text-white',
        transparent:
          'bg-white-600 ml-3 rounded-md border border-gray-300 py-2 font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg',
      };
      const sizes = {
        sm: 'h-8 px-2 text-xs rounded',
        md: 'px-3 text-sm h-10 rounded-md',
      };

      if (this.forIcon) {
        return {
          default: 'hover:bg-gray-200 p-1.5 rounded-full',
        };
      }

      return {
        color: [colors[this.color]],
        size: sizes[this.size],
        base: [
          this.fullwidth ? 'w-full text-center justify-center' : '',
          this.shadow ? 'shadow-sm' : '',
        ],
        default:
          'focus:outline-none inline-flex items-center gap-2 self-end border font-medium leading-4 focus:ring-2 focus:ring-offset-2 disabled:cursor-default disabled:opacity-50',
      };
    },
  },
  methods: {
    handleClick: function () {
      if (this.to) {
        this.$router.push(this.to);
      }
      this.$emit('click');
    },
  },
};
</script>

<template>
  <button
    v-if="!to"
    :class="[style.default, style.color, style.size, style.base]"
    :disabled="loading || disabled"
    v-on:click="handleClick"
  >
    <Icon
      icon="eos-icons:loading"
      v-if="loading"
      aria-hidden="true"
      class="text-white-200 inline h-4 w-4 animate-spin"
    />
    <slot />
  </button>
  <router-link
    v-else
    :to="to"
    :class="[style.default, style.color, style.size, style.base]"
    :disabled="loading || disabled"
    v-on:click="handleClick"
  >
    <Icon
      icon="eos-icons:loading"
      v-if="loading"
      aria-hidden="true"
      class="text-white-200 inline h-4 w-4 animate-spin"
    />
    <slot />
  </router-link>
</template>
