<template>
  <div class="flex flex-col divide-y text-gray-900">
    <div v-for="menu in menus" :key="menu.id" class="flex flex-col divide-y">
      <template v-if="menu.attributes.type === 'group'">
        <div class="py-2 px-3 text-gray-900 flex items-center justify-between cursor-pointer" :style="{ paddingLeft: `${depth * 1}rem` }" @click="onViewMenu(menu)">
          <span>{{ menu.attributes.name }}</span>
          <span>></span>
        </div>
        <mobile-navbar v-if="visibleId === menu.id" :parent-id="menu.id" :depth="depth + 1" :root="root ?? menu" @click-menu="onClickChildMenu" />
      </template>
      <a v-else :href="menu.attributes.url.charAt(0) === '/' ? menu.attributes.url : `/${menu.attributes.url}`" class="py-2 px-3 text-gray-900 block" :style="{ paddingLeft: `${depth * 1}rem` }" @click.prevent="$emit('click-menu', { parent: root, menu })">{{ menu.attributes.name }}</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MobileNavbar',
  props: {
    parentId: String,
    depth: {
      type: Number,
      default: 1
    },
    root: Object
  },
  emits: ['click-menu'],
  data() {
    return {
      visibleId: null
    }
  },
  computed: {
    ...mapGetters({
      getMenus: 'menus/getMenus',
    }),
    menus() {
      if (!this.parentId) {
        return this.getMenus.data.filter(menu => menu.attributes.parent_id === null)
      } else {
        return this.getMenus.data.filter(menu => menu.attributes.parent_id === this.parentId)
      }
    }
  },
  methods: {
    onViewMenu(menu) {
      if (this.visibleId === menu.id){
        this.visibleId = null
      } else {
        this.visibleId = menu.id
      }
    },
    onClickChildMenu(params) {
      this.$emit('click-menu', params)
    }
  },
}
</script>