import store from '@/store';
import VueRouter from 'vue-router';
import router from '@/router';
import { baseRoutes } from '@/router/base';

const availableMonthNames = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

export const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Ngambil relationships pada JSONApi
 * @params {Resource}
 * @returns {Array}
 */
export function getRelationships(resource, id) {
  let data = resource?.included?.find(function (el) {
    return el.id === id;
  });
  return data;
}

/**
 * Returns a date set to the begining of the month
 *
 * @param {Date} myDate
 * @returns {Date}
 */
export function beginningOfMonth(myDate) {
  let date = new Date(myDate);
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
}

/**
 * Returns a date set to the end of the month
 *
 * @param {Date} myDate
 * @returns {Date}
 */
export function endOfMonth(myDate) {
  let date = new Date(myDate);
  date.setDate(1); // Avoids edge cases on the 31st day of some months
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date;
}

/**
 * Untuk ngecek role user yg login
 * @returns
 */
export const checkRole = function () {
  return store.getters['auth/getUserOfficeCategory'];
};

/**
 * To reset Router
 */
const createRouter = () =>
  new VueRouter({
    mode: 'history',
    routes: baseRoutes,
  });

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // the relevant part
}

export function downloadFile(file, filename) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url)
}

export function downloadFileUrl(url, filename, options = {}) {
  const link = document.createElement('a');
  link.href = url;

  if (options?.blank) {
    link.target = '_blank'
  }

  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

export function getBalanceUsedMethodName(balanceUsed) {
  const balanceUsedMethodNames = {
    order: 'Saldo Pembayaran',
    stockist: 'Saldo Stockist',
  };

  return balanceUsedMethodNames[balanceUsed] ?? '-';
}

export function getMonthName(num, opt = {}) {
  const start = opt.start || 0;

  return availableMonthNames.at(num - start);
}

export function getCurrentMonthName() {
  return getMonthName(new Date().getMonth());
}

export function getMonthNameIndex(name, opt = {}) {
  const start = opt.start || 0;

  return availableMonthNames.findIndex((month) => month === name) + start;
}
