const LayoutDefault = () =>
  import(/* webpackChunkName: "kp" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "kp" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(/* webpackChunkName: "kp" */ '@/views/pelayanan/Index.vue');
const Stock = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/gudang/stock/Index.vue'
  );

const BarangMasuk = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/gudang/barang-masuk/Index.vue'
  );
const TambahBarangMasuk = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/gudang/barang-masuk/TambahBarangMasuk.vue'
  );

/**
 * Pengiriman
 */

// Pengiriman Retur
const ReturKePusat = () =>
  import(
    /* webpackChunkName: "kp-pengiriman" */ '@/views/pelayanan/gudang/pengiriman/retur-ke-pusat/Index.vue'
  );
const TambahReturKePusat = () =>
  import(
    /* webpackChunkName: "kp-pengiriman" */ '@/views/pelayanan/gudang/pengiriman/retur-ke-pusat/Tambah.vue'
  );

// Pengiriman ke SC
const PengirimanBarangkeStockistDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pelayanan/gudang/pengiriman/barang-ke-stockist-dikirim/Index.vue'
  );
const PengirimanBarangkeStockistDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pelayanan/gudang/pengiriman/barang-ke-stockist-diambil/Index.vue'
  );
const TambahPengirimanBarangkeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pelayanan/gudang/pengiriman/barang-ke-stockist/Tambah.vue'
  );
// pengiriman ke SC
const PengirimanBarangKeSCDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pelayanan/gudang/pengiriman/barang-ke-sc-diambil/Index.vue'
  );
const PengirimanBarangKeSCDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pelayanan/gudang/pengiriman/barang-ke-sc-dikirim/Index.vue'
  );
const TambahPengirimanBarangKeSC = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pelayanan/gudang/pengiriman/barang-ke-sc/Tambah.vue'
  );

/**
 * Pengeluaran
 */
// Pengeluaran ke SC
const PengeluaranBarangKeStockistDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pelayanan/gudang/pengeluaran/barang-ke-stockist-dikirim/Index.vue'
  );
const PengeluaranBarangKeStockistDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pelayanan/gudang/pengeluaran/barang-ke-stockist-diambil/Index.vue'
  );
const PengeluaranRestockBarangKeStockistDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pelayanan/gudang/pengeluaran/restock-barang-ke-stockist-dikirim/Index.vue'
  );
const PengeluaranRestockBarangKeStockistDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pelayanan/gudang/pengeluaran/restock-barang-ke-stockist-diambil/Index.vue'
  );
const DetailPengeluaranBarangKeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pelayanan/gudang/pengeluaran/barang-ke-stockist/DetailPengiriman.vue'
  );
const PengemasanBarangKeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pelayanan/gudang/pengeluaran/barang-ke-stockist/Pengemasan.vue'
  );
// pengeluaran ke sc
const PengeluaranKeSCDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pelayanan/gudang/pengeluaran/barang-ke-sc-dikirim/Index.vue'
  );
const PengeluaranKeSCDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pelayanan/gudang/pengeluaran/barang-ke-sc-diambil/Index.vue'
  );
const DetailPengeluaranBarangKeSC = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pelayanan/gudang/pengeluaran/barang-ke-sc/DetailPengiriman.vue'
  );
const PengemasanBarangKeSC = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pelayanan/gudang/pengeluaran/barang-ke-sc/Pengemasan.vue'
  );

/**
 * Susulan
 */
// Susulan ke SC
const SusulanBarangKeSC = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/gudang/susulan/barang-ke-sc/Index.vue'
  );
const TambahSusulanBarangKeSC = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/gudang/susulan/barang-ke-sc/Tambah.vue'
  );
const PengemasanSusulanBarangKeSC = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/gudang/susulan/barang-ke-sc/Detail.vue'
  );

// Susulan ke Stockist
const SusulanBarangKeStockist = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/gudang/susulan/barang-ke-stockist/Index.vue'
  );
const TambahSusulanBarangKeStockist = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/gudang/susulan/barang-ke-stockist/Tambah.vue'
  );
const PengemasanSusulanBarangKeStockist = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/gudang/susulan/barang-ke-stockist/Detail.vue'
  );

const FakturKP = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/gudang/faktur-kp/Index.vue'
  );
const Laporan = () =>
  import(/* webpackChunkName: "kp" */ '@/views/pelayanan/Laporan.vue');

// Setup
const Setup = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/gudang/setup/Index.vue'
  );

const ReturPusat = () =>
  import(
    /* webpackChunkName: "admin-gudang" */ '@/views/pelayanan/gudang/retur/pusat/Index.vue'
  );
const TambahReturPusat = () =>
  import(
    /* webpackChunkName: "admin-gudang" */ '@/views/pelayanan/gudang/retur/pusat/Tambah.vue'
  );

let root = '/gudang';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Kantor Pelayanan',
      layout: LayoutDefault,
    },
  },
  // Barang Masuk
  {
    path: root + '/penerimaan-barang-dari-pusat/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Penerimaan Barang dari Pusat',
    },
    children: [
      {
        path: '',
        component: BarangMasuk,
        meta: {
          auth: true,
          title: 'Penerimaan Barang Masuk | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Barang dari Pusat',
        },
      },
      {
        path: 'tambah-barang-masuk',
        component: TambahBarangMasuk,
        meta: {
          auth: true,
          title: 'Tambah Barang dari Pusat | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Penerimaan Barang',
        },
      },
      {
        path: ':id',
        component: TambahBarangMasuk,
        meta: {
          auth: true,
          title: 'Tambah Barang dari Pusat | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Penerimaan Barang',
        },
      },
    ],
  },
  {
    path: root + '/stock/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Stock',
    },
    children: [
      {
        path: '',
        component: Stock,
        meta: {
          auth: true,
          title: 'Stock | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  // Pengiriman
  // Pengiriman ke SC
  {
    path: root + '/pengiriman/barang-ke-stockist-dikirim/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengiriman Barang ke Stockist Dikirim',
    },
    children: [
      {
        path: '',
        component: PengirimanBarangkeStockistDikirim,
        meta: {
          auth: true,
          title: 'Pengiriman Barang ke Stockist Dikirim | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id',
        component: TambahPengirimanBarangkeStockist,
        name: 'gudang.pengiriman.barang-ke-stockist-dikirim.detail',
        meta: {
          auth: true,
          title: 'Tambah Pengiriman Barang ke Stockist Dikirim | Gudang',
          layout: LayoutDefault,
        },
        props: {
          indexPath: '/gudang/pengiriman/barang-ke-stockist-dikirim'
        }
      },
    ],
  },
  {
    path: root + '/pengiriman/barang-ke-stockist-diambil/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengiriman Barang ke Stockist Diambil',
    },
    children: [
      {
        path: '',
        component: PengirimanBarangkeStockistDiambil,
        meta: {
          auth: true,
          title: 'Pengiriman Barang ke Stockist Diambil | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id',
        component: TambahPengirimanBarangkeStockist,
        name: 'gudang.pengiriman.barang-ke-stockist-diambil.detail',
        meta: {
          auth: true,
          title: 'Tambah Pengiriman Barang ke Stockist Diambil | Gudang',
          layout: LayoutDefault,
        },
        props: {
          indexPath: '/gudang/pengiriman/barang-ke-stockist-diambil'
        },
      }
    ],
  },
  {
    path: root + '/pengiriman/barang-ke-sc-diambil/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengiriman Barang ke SC Diambil',
    },
    children: [
      {
        path: '',
        component: PengirimanBarangKeSCDiambil,
        meta: {
          auth: true,
          title: 'Pengiriman Barang ke SC Diambil | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id',
        component: TambahPengirimanBarangKeSC,
        name: 'gudang.pengiriman.barang-ke-sc-diambil.detail',
        meta: {
          auth: true,
          title: 'Tambah Pengiriman Barang ke SC Diambil | Gudang',
          layout: LayoutDefault,
        },
        props: {
          indexPath: '/gudang/pengiriman/barang-ke-sc-diambil'
        },
      },
    ],
  },
  {
    path: root + '/pengiriman/barang-ke-sc-dikirim/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengiriman Barang ke SC Dikirim',
    },
    children: [
      {
        path: '',
        component: PengirimanBarangKeSCDikirim,
        meta: {
          auth: true,
          title: 'Pengiriman Barang ke SC Dikirim | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id',
        component: TambahPengirimanBarangKeSC,
        name: 'gudang.pengiriman.barang-ke-sc-dikirim.detail',
        meta: {
          auth: true,
          title: 'Tambah Pengiriman Barang ke SC Dikirim | Gudang',
          layout: LayoutDefault,
        },
        props: {
          indexPath: '/gudang/pengiriman/barang-ke-sc-dikirim'
        },
      },
    ],
  },
  {
    path: root + '/pengiriman/retur-ke-pusat',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengiriman Retur ke Pusat',
    },
    children: [
      {
        path: '',
        component: ReturKePusat,
        meta: {
          auth: true,
          title: 'Pengiriman Retur Barang ke Pusat | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id',
        component: TambahReturKePusat,
        meta: {
          auth: true,
          title: 'Pengiriman Retur Barang ke Pusat | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },

  {
    path: root + '/pengeluaran/retur',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengiriman Retur ke Pusat',
    },
    children: [
      {
        path: '',
        component: ReturKePusat,
        meta: {
          auth: true,
          title: 'Pengiriman Retur Barang ke Pusat | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  /**
   *
   *
   * Pengeluaran
   *
   */

  /**
   * Pengeluaran Stockist
   */
  {
    path: root + '/pengeluaran/barang-ke-stockist-dikirim/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengeluaran Barang ke Stockist',
    },
    children: [
      {
        path: '',
        component: PengeluaranBarangKeStockistDikirim,
        meta: {
          auth: true,
          title: 'Pengeluaran Barang ke Stockist Dikirim | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Detail Pengeluaran Barang ke Stockist Dikirim',
        },
        children: [
          {
            path: '',
            component: DetailPengeluaranBarangKeStockist,
            name: 'gudang.pengeluaran.barang-ke-stockist-dikirim.detail',
            meta: {
              auth: true,
              title: 'Detail Pengeluaran Barang ke Stockist Dikirim | Gudang',
              layout: LayoutDefault,
            },
            props: {
              indexPath: '/gudang/pengeluaran/barang-ke-stockist-dikirim'
            }
          },
          {
            path: 'pengemasan',
            component: PengemasanBarangKeStockist,
            meta: {
              auth: true,
              title: 'Pengemasan Barang | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengemasan Barang ke Stockist Dikirim',
            },
            props: {
              indexPath: '/gudang/pengeluaran/barang-ke-stockist-dikirim'
            }
          }
        ],
      },
    ],
  },
  {
    path: root + '/pengeluaran/barang-ke-stockist-diambil/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengeluaran Barang ke Stockist',
    },
    children: [
      {
        path: '',
        component: PengeluaranBarangKeStockistDiambil,
        meta: {
          auth: true,
          title: 'Pengeluaran Barang ke Stockist Diambil | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Detail Pengeluaran Barang ke Stockist Diambil',
        },
        children: [
          {
            path: '',
            component: DetailPengeluaranBarangKeStockist,
            name: 'gudang.pengeluaran.barang-ke-stockist-diambil.detail',
            meta: {
              auth: true,
              title: 'Detail Pengeluaran Barang ke Stockist Diambil | Gudang',
              layout: LayoutDefault,
            },
            props: {
              indexPath: '/gudang/pengeluaran/barang-ke-stockist-diambil'
            }
          },
          {
            path: 'pengemasan',
            component: PengemasanBarangKeStockist,
            meta: {
              auth: true,
              title: 'Pengemasan Barang | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengemasan Barang ke Stockist Diambil',
            },
            props: {
              indexPath: '/gudang/pengeluaran/barang-ke-stockist-diambil'
            }
          },
        ],
      },
    ],
  },
  {
    path: root + '/pengeluaran/barang-ke-sc-dikirim/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengeluaran Barang ke SC',
    },
    children: [
      {
        path: '',
        component: PengeluaranKeSCDikirim,
        meta: {
          auth: true,
          title: 'Pengeluaran Barang ke SC Dikirim | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Detail Pengeluaran Barang ke SC Dikirim',
        },
        children: [
          {
            path: '',
            name: 'gudang.pengeluaran.barang-ke-sc-dikirim.detail',
            component: DetailPengeluaranBarangKeSC,
            meta: {
              auth: true,
              title: 'Detail Pengeluaran Barang SC Dikirim | Gudang',
              layout: LayoutDefault
            },
            props: {
              indexPath: '/gudang/pengeluaran/barang-ke-sc-dikirim'
            }
          },
          {
            path: 'pengemasan',
            component: PengemasanBarangKeSC,
            meta: {
              auth: true,
              title: 'Pengemasan Barang | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengemasan Barang ke SC Dikirim',
            },
            props: {
              indexPath: '/gudang/pengeluaran/barang-ke-sc-dikirim'
            }
          },
        ],
      },
    ],
  },
  {
    path: root + '/pengeluaran/barang-ke-sc-diambil/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengeluaran Barang ke SC',
    },
    children: [
      {
        path: '',
        component: PengeluaranKeSCDiambil,
        meta: {
          auth: true,
          title: 'Pengeluaran Barang ke SC Diambil | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Detail Pengeluaran Barang ke SC Diambil',
        },
        children: [
          {
            path: '',
            component: DetailPengeluaranBarangKeSC,
            name: 'gudang.pengeluaran.barang-ke-sc-diambil.detail',
            meta: {
              auth: true,
              title: 'Detail Pengeluaran Barang SC Diambil | Gudang',
              layout: LayoutDefault
            },
            props: {
              indexPath: '/gudang/pengeluaran/barang-ke-sc-diambil'
            },
          },
          {
            path: 'pengemasan',
            component: PengemasanBarangKeSC,
            meta: {
              auth: true,
              title: 'Pengemasan Barang | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengemasan Barang ke SC Diambil',
            },
            props: {
              indexPath: '/gudang/pengeluaran/barang-ke-sc-diambil'
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/pengeluaran/restock-barang-ke-stockist-dikirim/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengeluaran Restock Barang ke Stockist',
    },
    children: [
      {
        path: '',
        component: PengeluaranRestockBarangKeStockistDikirim,
        meta: {
          auth: true,
          title: 'Pengeluaran Restock Barang ke Stockist Dikirim | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Detail Pengeluaran Restock Barang ke Stockist Dikirim',
        },
        children: [
          {
            path: '',
            component: DetailPengeluaranBarangKeStockist,
            name: 'gudang.pengeluaran.restock-barang-ke-stockist-dikirim.detail',
            meta: {
              auth: true,
              title: 'Detail Pengeluaran Restock Barang ke Stockist Dikirim | Gudang',
              layout: LayoutDefault,
            },
            props: {
              indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-dikirim'
            }
          },
          {
            path: 'pengemasan',
            component: PengemasanBarangKeStockist,
            meta: {
              auth: true,
              title: 'Pengemasan Barang | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengemasan Barang ke Stockist Dikirim',
            },
            props: {
              indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-dikirim'
            }
          }
        ],
      },
    ],
  },
  {
    path: root + '/pengeluaran/restock-barang-ke-stockist-diambil/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pengeluaran Restock Barang ke Stockist',
    },
    children: [
      {
        path: '',
        component: PengeluaranRestockBarangKeStockistDiambil,
        meta: {
          auth: true,
          title: 'Pengeluaran Restock Barang ke Stockist Diambil | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Detail Pengeluaran Restock Barang ke Stockist Diambil',
        },
        children: [
          {
            path: '',
            component: DetailPengeluaranBarangKeStockist,
            name: 'gudang.pengeluaran.restock-barang-ke-stockist-diambil.detail',
            meta: {
              auth: true,
              title: 'Detail Pengeluaran Restock Barang ke Stockist Diambil | Gudang',
              layout: LayoutDefault,
            },
            props: {
              indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-diambil'
            }
          },
          {
            path: 'pengemasan',
            component: PengemasanBarangKeStockist,
            meta: {
              auth: true,
              title: 'Pengemasan Barang | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengemasan Barang ke Stockist Diambil',
            },
            props: {
              indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-diambil'
            }
          },
        ],
      },
    ],
  },
  {
    path: root + '/setup/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Setup',
    },
    children: [
      {
        path: '',
        component: Setup,
        meta: {
          auth: true,
          title: 'Setup | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },

  /**
   * Susulan
   */
  {
    path: root + '/susulan/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Susulan',
    },
    children: [
      {
        path: 'barang-ke-stockist/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Susulan ke Stockist',
        },
        children: [
          {
            path: '',
            component: SusulanBarangKeStockist,
            meta: {
              auth: true,
              title: 'Pengiriman Susulan ke Stockist | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahSusulanBarangKeStockist,
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Susulan ke Stockist | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengiriman Susulan ke Stockist',
            },
          },
          {
            path: ':id/pengemasan',
            component: PengemasanSusulanBarangKeStockist,
            meta: {
              auth: true,
              title: 'Pengemasan Susulan ke Stockist | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengemasan Susulan ke Stockist',
            },
          },
        ],
      },
      {
        path: 'barang-ke-sc/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Susulan ke SC',
        },
        children: [
          {
            path: '',
            component: SusulanBarangKeSC,
            meta: {
              auth: true,
              title: 'Pengiriman Susulan ke SC | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahSusulanBarangKeSC,
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Susulan ke SC | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengiriman Susulan ke SC',
            },
          },
          {
            path: ':id/pengemasan',
            component: PengemasanSusulanBarangKeSC,
            meta: {
              auth: true,
              title: 'Pengemasan Susulan ke SC | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengemasan Susulan ke SC',
            },
          },
        ],
      },
    ],
  },

  // Faktur KP
  {
    path: root + '/faktur-kp',
    component: FakturKP,
    meta: {
      auth: true,
      title: 'Faktur KP | Kantor Pelayanan',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/laporan',
    component: Laporan,
    meta: {
      auth: true,
      title: 'Laporan Page | Kantor Pelayanan',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/retur/pusat',
    component: LayoutData,
    meta: {
      breadcrumb: 'Retur',
    },
    children: [
      {
        path: '',
        component: ReturPusat,
        name: 'index.retur.pusat',
        meta: {
          auth: true,
          title: 'Retur ke Pusat | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-retur',
        component: TambahReturPusat,
        name: 'create.retur.pusat',
        meta: {
          auth: true,
          title: 'Tambah Retur ke Pusat | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Retur',
        },
      },
      {
        path: 'tambah-retur/:id',
        component: TambahReturPusat,
        name: 'edit.retur.pusat',
        meta: {
          auth: true,
          title: 'Edit Retur ke Pusat | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
];
