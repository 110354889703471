/*
  NOTE: We don't need susulan menu on stockist
*/

const LayoutDefault = () =>
  import(/* webpackChunkName: "stockist" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "stockist" */ '@/layouts/LayoutData.vue');

const Dashboard = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/dashboard/Index.vue'
  );

const Penerimaan = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/penerimaan/Index.vue'
  );
const Pengeluaran = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/pengeluaran/Index.vue'
  );

// penerimaan barang
// dari SC
const PenerimaanBarangMasukDariSC = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/penerimaan/barang-dari-sc/Index.vue'
  );
const TambahPenerimaanBarangMasukDariSC = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/penerimaan/barang-dari-sc/TambahPenerimaanBarangMasuk.vue'
  );
// dari KP
const PenerimaanBarangMasukDariKP = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/penerimaan/barang-dari-kp/Index.vue'
  );
const TambahPenerimaanBarangMasukDariKP = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/penerimaan/barang-dari-kp/TambahPenerimaanBarangMasuk.vue'
  );
// dari Pusat
const PenerimaanBarangMasukDariPusat = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/penerimaan/barang-dari-pusat/Index.vue'
  );
const TambahPenerimaanBarangMasukDariPusat = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/penerimaan/barang-dari-pusat/TambahPenerimaanBarangMasuk.vue'
  );

// pengeluaran barang
const DetailPengeluaranBarangKeMember = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/pengeluaran/barang-ke-member/DetailPengiriman.vue'
  );

// pengiriman barang
const PengirimanBarangkeMember = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/pengiriman/barang-ke-member/Index.vue'
  );
const TambahPengirimanBarangkeMember = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/pengiriman/barang-ke-member/Tambah.vue'
  );

const ReturKePusat = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/pengiriman/retur-ke-pusat/Index.vue'
  );
const TambahReturKePusat = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/pengiriman/retur-ke-pusat/Tambah.vue'
  );

const Stock = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/stock/Index.vue'
  );
const Setup = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/setup/Index.vue'
  );

const Pembelian = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/pembelian/Index.vue'
  );
const TambahPembelian = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/pembelian/TambahPembelian.vue'
  );

const Susulan = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/susulan/Index.vue'
  );

const RestockPbs = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/restock-pbs/Index.vue'
  );
const TambahRestockSc = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/gudang/restock-pbs/Tambah.vue'
  );

let root = '/gudang';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Gudang',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/stock/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Stock',
    },
    children: [
      {
        path: '',
        component: Stock,
        meta: {
          auth: true,
          title: 'Stock | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },

  /*
  
  PENERIMAAN BARANG
  |
  -> DARI PUSAT
  |
  -> DARI STOCKIST CENTER
  |
  -> DARI KANTOR PELAYANAN

  */
  {
    path: root + '/penerimaan/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: Penerimaan,
        meta: {
          breadcrumb: 'Penerimaan',
          layout: LayoutDefault,
          title: 'Penerimaan | ',
        },
      },

      /*  DARI PUSAT */

      {
        path: 'barang-dari-pusat/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Penerimaan Barang dari Pusat',
        },
        children: [
          {
            path: '',
            component: PenerimaanBarangMasukDariPusat,
            meta: {
              auth: true,
              title: 'Penerimaan Barang Masuk dari Pusat | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Penerimaan',
            },
          },
          {
            path: 'tambah-barang-masuk',
            component: TambahPenerimaanBarangMasukDariPusat,
            meta: {
              auth: true,
              title: 'Tambah Barang Masuk dari Pusat | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Penerimaan Barang',
            },
          },
          {
            path: ':id',
            component: TambahPenerimaanBarangMasukDariPusat,
            meta: {
              auth: true,
              title: 'Tambah Barang Masuk | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Penerimaan Barang',
            },
          },
        ],
      },

      /*  DARI KANTOR PELAYANAN(KP) */

      {
        path: 'barang-dari-kp/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Penerimaan Barang dari KP',
        },
        children: [
          {
            path: '',
            component: PenerimaanBarangMasukDariKP,
            meta: {
              auth: true,
              title: 'Penerimaan Barang Masuk dari KP | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Barang Masuk',
            },
          },
          {
            path: 'tambah-barang-masuk',
            component: TambahPenerimaanBarangMasukDariKP,
            meta: {
              auth: true,
              title: 'Tambah Barang Masuk dari KP | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Penerimaan Barang',
            },
          },
          {
            path: ':id',
            component: TambahPenerimaanBarangMasukDariKP,
            meta: {
              auth: true,
              title: 'Tambah Barang Masuk dari KP | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Penerimaan Barang',
            },
          },
        ],
      },

      /*  DARI STOCKIST CENTER(SC) */

      {
        path: 'barang-dari-sc/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Penerimaan Barang dari SC',
        },
        children: [
          {
            path: '',
            component: PenerimaanBarangMasukDariSC,
            meta: {
              auth: true,
              title: 'Penerimaan Barang Masuk dari Stockist Center | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Barang Masuk',
            },
          },
          {
            path: 'tambah-barang-masuk',
            component: TambahPenerimaanBarangMasukDariSC,
            meta: {
              auth: true,
              title: 'Tambah Barang Masuk Dari SC | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Penerimaan Barang',
            },
          },
          {
            path: ':id',
            component: TambahPenerimaanBarangMasukDariSC,
            meta: {
              auth: true,
              title: 'Tambah Barang Masuk Dari SC | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Penerimaan Barang',
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/pembelian/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pembelian',
    },
    children: [
      {
        path: '',
        component: Pembelian,
        meta: {
          auth: true,
          title: 'Order Barang | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-pembelian',
        component: TambahPembelian,
        meta: {
          auth: true,
          title: 'Tambah Order Barang | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Pembelian',
        },
      },
      {
        path: ':id',
        component: TambahPembelian,
        meta: {
          auth: true,
          title: 'Tambah Pembelian | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Edit Pembelian',
        },
      },
    ],
  },
  {
    path: root + '/restock-pbs/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Restock P3S',
    },
    children: [
      {
        path: '',
        component: RestockPbs,
        name: 'restock-pbs',
        meta: {
          auth: true,
          title: 'Restock P3S | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah',
        component: TambahRestockSc,
        name: 'restock-pbs.tambah',
        meta: {
          auth: true,
          title: 'Tambah Restock P3S | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: ':id',
        component: TambahRestockSc,
        name: 'restock-pbs.edit',
        meta: {
          auth: true,
          title: 'Edit Restock P3S | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/pengeluaran/',
    component: LayoutData,
    children: [
      // rabih here
      {
        path: '',
        component: Pengeluaran,
        meta: {
          breadcrumb: 'Pengeluaran',
          layout: LayoutDefault,
          title: 'Pengeluaran | ',
        },
      },
      {
        path: 'barang-ke-member/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengeluaran Barang ke Stockist',
        },
        children: [
          {
            path: ':id/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Detail Pengeluaran Barang ke Mitra Usaha',
            },
            children: [
              {
                path: '',
                component: DetailPengeluaranBarangKeMember,
                meta: {
                  auth: true,
                  title: 'Detail Pengeluaran Barang | Gudang',
                  layout: LayoutDefault,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: root + '/pengiriman/',
    component: LayoutData,
    children: [
      {
        path: 'barang-ke-mitra',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Barang ke Mitra',
        },
        children: [
          {
            path: '',
            component: PengirimanBarangkeMember,
            meta: {
              auth: true,
              title: 'Pengiriman Barang ke Mitra | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahPengirimanBarangkeMember,
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Barang ke Mitra | Gudang',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'retur-ke-pusat',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Retur ke Pusat',
        },
        children: [
          {
            path: '',
            component: ReturKePusat,
            meta: {
              auth: true,
              title: 'Pengiriman Retur Barang ke Pusat | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahReturKePusat,
            meta: {
              auth: true,
              title: 'Pengiriman Retur Barang ke Pusat | Gudang',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/setup/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Setup',
    },
    children: [
      {
        path: '',
        component: Setup,
        meta: {
          auth: true,
          title: 'Setup | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/susulan/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Susulan',
    },
    children: [
      {
        path: '',
        component: Susulan,
        meta: {
          auth: true,
          title: 'Susulan | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
];
