<template>
    <div class="mx-auto my-4 px-4 sm:px-4 lg:px-8">
        <slot />

        <loading v-if="loading" />
    </div>
</template>

<script>
export default {
    props: {
        loading: Boolean
    }
}
</script>